@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-primary: #4885ff;
@shopnosis-black: #49495a;

@primary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
@text-color: @shopnosis-black;

@label-color: @text-color;

[data-component="PageSingleProject"] {
  display: flex;
  flex-direction: column;
  flex: 1;
  > .ant-form,
  > .ant-result > .ant-result-extra .ant-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .actions {
    [data-actions-side="left"] {
      button {
        margin-right: 12px !important;
      }
    }
    [data-actions-side="right"] {
      button {
        margin-left: 12px !important;
      }
    }
    &.actions-bottom {
      > .ant-divider-horizontal {
        margin-top: 0px;
        margin-bottom: 18px;
      }
      button {
        margin-top: 12px !important;
      }
    }
  }
  .project-step-title {
    &:hover {
      color: @primary-color;
    }
  }
  form textarea.ant-input {
    margin-bottom: 0px;
    &::placeholder {
      color: #999;
    }
  }
}

.project-products,
.project-pos-materials {
  .ant-row.ant-form-item {
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
  .ant-form.ant-form-vertical {
    > .ant-row {
      margin-bottom: -20px;
      &:last-child {
        margin-bottom: -20px;
      }
    }
  }
}

.project-variations {
  .ant-form.ant-form-vertical {
    display: flex;
    > .ant-row {
      &:before, &:after { display: none }
      display: flex;
      flex-wrap: wrap;
      > .ant-col {
        display: flex;
        width: auto;
      }
      .ant-col.ant-form-item-label {
        display: flex;
        margin-left: 0px;
        text-align: center;
        padding-bottom: 0px;
        margin-bottom: -5px;
        margin-top: 20px;
        flex: 1;
        > label {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: left;
          max-width: 100px;
        }
      }
      .ant-row.ant-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
      .ant-upload-list-picture-card-container {
        margin: 0px;
      }
      .ant-upload-list-picture-card .ant-upload-list-item {
        margin: 0px;
      }
      .ant-upload.ant-upload-select.ant-upload-select-picture-card {
        margin-bottom: 0px;
        margin-right: 0px;
      }
    }
  }
}
