@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-primary: #4885ff;
@shopnosis-black: #49495a;

@primary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
@text-color: @shopnosis-black;

@label-color: @text-color;

@includeLessVars: {
  display: none !important;
  @vars: primary-color,primary-1,primary-2,background-color-light,text-color,disabled-color,error-color,red-2,border-color-base,border-radius-base;
  .-(@i: length(@vars)) when (@i > 0)
  {
    @name: extract(@vars, @i);
    --@{name}: @@name !important;
    .-((@i - 1));
  }
  .-;
}

@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/components.less';

#__LESSVARS__ {
  @includeLessVars();
}
