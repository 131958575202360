@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-primary: #4885ff;
@shopnosis-black: #49495a;

@primary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
@text-color: @shopnosis-black;

@label-color: @text-color;

.field-sortable-container {
  width: 100%;
}

.field-sortable-picker {
  .ant-modal-body {
    padding: 15px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > input {
      width: auto;
    }
  }
  .scroller {
    display: flex;
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > div {
      width: 100%;
    }
  }
}
