@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-primary: #4885ff;
@shopnosis-black: #49495a;

@primary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
@text-color: @shopnosis-black;

@label-color: @text-color;

.planogram-editor-modal-wrap {
  .planogram-editor-sidebar {
    border-right-color: @border-color-base;
    // .sidebar-header {
    //   border-bottom-color: @border-color-base;
    // }
    .planogram-editor-sidebar-header {
      border-bottom-color: @border-color-base;
      .planogram-editor-sidebar-header-tab {
        &:hover {
          color: @primary-color;
        }
        &[data-active="true"] {
          color: @primary-color;
          border-bottom-color: @primary-color;
        }
      }
      .planogram-editor-sidebar-header-tab-track {
        background-color: @primary-color;
      }
    }
    .planogram-editor-sidebar-body {
      .planogram-editor-sidebar-body-header {
        border-bottom-color: @border-color-base;
      }
    }
  }
  .planogram-editor-body {
    .planogram-editor-body-header {
      border-bottom-color: @border-color-base;
      .shelf-switcher {
        &:hover, &.ant-cascader-picker-focused {
          color: @primary-color;
        }
      }
      .planogram-editor-body-header-toolbar-button {
        &:hover, &.ant-popover-open {
          color: @primary-color;
        }
      }
    }
    .planogram-editor-body-shelf {
      .planogram-editor-body-shelf-header {
        .planogram-editor-body-shelf-header-title {
          &:hover {
            color: @primary-color;
          }
        }
        .planogram-editor-body-shelf-header-settings {
          &:hover, &.ant-popover-open {
            color: @primary-color;
          }
        }
      }
      .planogram-editor-body-shelf-body {
        border-color: @border-color-base;
        .planogram-editor-body-shelf-segment-pricing {
          border-color: @border-color-base;
        }
      }
    }
  }
}

.planogram-editor-group-settings {
  .ant-popover-inner-content {
    padding: 4px 6px;
  }
}

.toolbar-item-variations-dropdown {
  ul.ant-select-dropdown-menu-root {
    text-align: center;
  }
}

.shelf-switcher-popup {
  .ant-cascader-menu {
    padding: 10px 0px;
    width: 150px;
    .ant-cascader-menu-item {
      padding: 5px 20px;
    }
  }
}
